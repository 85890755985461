#timeRemaining {
    color: #fafafa;
    
    text-shadow: 3px 3px 0px #713699;
    font-size: 2.2em;
    margin: auto;
    text-align: center;
    user-select: none;
}
.timeOff {
    opacity: 0;
}


.timeBarContainer {
    width: 100%;
    padding: 4px;
    margin: 4px 0px;
    border-radius: 100px;
    background-color: rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
}

.timeBar {
    height: 3px;
    background-color: #fafafa;
    border-radius: 100px;
    transition: width 1s linear;
}

.endRoundTimeBar {
    animation: endRoundTimeBar 10s linear;
}

.source{
    color: #fafafa;
    font-variant: small-caps;
    font-size: .4em;
    margin-left: 10px
}

@keyframes endRoundTimeBar {
    0% {
        width: 0%;
    }
    100% {
        width: 100%;
    }
}