#ScoreBoard {
    width: 70vw;
    margin-left: 15vw;
}

.scoreBoard_List {
    list-style: none;
    counter-reset: custom-counter;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    height: 40vh;
}


.scoreBoard_Item {
    counter-increment: custom-counter;
    background-color: #ede8ee;
    /* height: calc(10vh - 30px);
    line-height: calc(10vh - 30px); */
    display: inline-block;
    text-align: center;
    margin: 5px;
    font-weight: 600;
    font-size: 1.4em;
    box-shadow: 4px 4px 0px rgba(42, 20, 66, 0.4);
    animation: scoreBoardLine2 1.2s ease-out 1.2s backwards;
}

.scoreBoard_Item::before {
    content: counter(custom-counter) ". ";
}

.scoreBoard_Item:nth-child(1) {
    font-size: 2.2em;
    color:#fafafa;
    text-shadow: 2px 2px 0px #8f7648;
    background: linear-gradient(-45deg,#b69b6a,  #ddd7b3,#b69b6a, #8f7648);
    animation: scoreBoardLine .5s ease-out backwards;
}
.scoreBoard_Item::before {
    font-weight: bold;
}
.scoreBoard_Item:nth-child(2) {
    font-size: 1.8em;
    color:#fafafa;
    text-shadow: 2px 2px 0px #636c6d;
    background: linear-gradient(-45deg,#98a7ad,  #d9e6e9,#98a7ad, #636c6d);
    animation: scoreBoardLine .5s ease-out .2s backwards;
}
.scoreBoard_Item:nth-child(3) {
    font-size: 1.6em;
    color:#fafafa;
    text-shadow: 2px 2px 0px #8f5c48;
    background: linear-gradient(-45deg,#b6826a,  #ddbfb3,#b67c6a, #8f5c48);
    animation: scoreBoardLine .5s ease-out .4s backwards;
}
.scoreBoard_Score {
    margin-left: 15px;
    opacity: .6;
}
.list_You {
    font-weight: bold;
}

.restartButton {
    width: 40vw;
    margin-left: 16vw;
}

@keyframes scoreBoardLine {
    0% {
        transform: translateX(50px);
        opacity: 0;
    }
    100% {
        transform: translateX(0px);
        opacity: 1;
    }
}

@keyframes scoreBoardLine2 {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@media only screen and (max-width: 800px) {
    #ScoreBoard {
        width:calc(100vw - 10px);
        margin: 5px;
    }
    .scoreBoard_List {
        padding: 0;
    }
    .restartButton {
        width: calc(100vw - 20px);
        margin: 5px;
        box-sizing: border-box;        
    }
}