#MainInput {
    margin-top: 2vh;
}
.mainInputForm {
    margin: auto;
    width: 40%;
    display: flex;
}

.mainInputInput {
    box-sizing: border-box;
    text-align: center;
    flex-grow: 1;
    font-size: 1.6em;
    padding: 5px;
    border: 0;
    margin: auto;
    border-bottom: 3px solid #CCC;
    font-family: 'open-sans',sans-serif;
    transition: all .2s ease-in-out;
    background-color: transparent;
    font-family: 'neue-kabel';
    width: 100px;
}

.mainInputInput:hover { 
    border-bottom: 3px solid rgb(216, 193, 231);
 }

.mainInputInput:focus { 
    outline: none;
    border-bottom: 3px solid rgb(179, 132, 211);
}

.submitMessage, .submitAnswer {
    font-size: 1.5em;
    font-weight: bold;
    border: none;
    margin: 5px 0px;
    margin-left: 20px;
    border-radius: 300px;
    color: #faf9f4;
    cursor: pointer;
    transition: all .1s ease-in-out;
    box-sizing: border-box;
    aspect-ratio: 1;
    font-family: 'neue-kabel',sans-serif;
    width: 40px;
    height: 40px;
}

.submitAnswer {
    background-color: rgb(75, 179, 97);
    border: 1px solid rgb(61, 150, 81);
    text-shadow: 1px 1px 0px rgb(61, 150, 81);
    transform: rotate(-90deg);
}
.submitAnswer:hover {
    background-color: rgb(100, 199, 122);
}
.submitAnswer:active {
    background-color: rgb(61, 150, 81);
}

.submitMessage {
    background-color: rgb(126, 167, 241);
    border: 1px solid rgb(108, 138, 194);
    text-shadow: 1px 1px 0px rgb(108, 138, 194);
}
.submitMessage:hover{
    background-color: rgb(146, 180, 245);
}
.submitMessage:active{
    border: 1px solid rgb(108, 138, 194);
}



@media only screen and (max-width: 800px) {
    #MainInput {
        margin-top: 5px;
        order: 2;
        position: absolute;
        bottom: 0;
        background-color: #fdfdfd;
        width: 100vw;
        height: 50px;
    }
    .mainInputForm {
        width: calc(100vw - 40px);
        margin: auto;
    }
}