#AddEntry {
    padding: 60px;
    white-space: break-spaces;
}
#App {
    overflow:auto!IMPORTANT;
}

.addEntryForm {
    width: 100%;
    text-align: center;

}

.addEntryQuery {
    font-size: 1.8em;
    padding: 7px;
    box-shadow: 1px 1px 7px #0e133062;
    border: none;
    width: 30vw;
    text-align: center;
}
.addEntryQuery::placeholder {
    opacity: .2;
}
.addEntryExtract {
    width: 100%;
    height: 200px;
    margin-bottom: 20px;
    border-color: #85858a17;
}

.addEntrySubmit {
    font-size: 1.6em;
    background-color: rgb(51, 120, 167);
    box-shadow: 1px 1px 7px #0e13303d;
    border: none;
    padding:15px 25px;
    color: white;
    border-radius: 4px;
    cursor: pointer;
    transition: all .1s ease-in-out;
}
.addEntrySubmit:hover {
    background-color: rgb(81, 144, 187);
}
.addEntrySubmit:disabled {
    background-color: rgb(119, 132, 141);
}