.extractPanel {
	width: 60vw;
	margin-left: 10vw;
	margin-top: 4vw;
}


#Extract {
	color: #fafafa;
	font-size: 1.4em;
	line-height: 1.5em;
	max-height: 50vh;
	overflow: hidden;
	white-space: break-spaces;
	overflow: auto;
}

.wordHidden {
    display: none;
}
.wordDisplay {
	display: inline;
	animation: wordDisplay .5s ease-out;
	user-select: none;
}

.obfuscatedWord {
	/* opacity: 0; */
	
    color: #faf9f400;
    background-image: linear-gradient(to right, #eae3ee 0%, #9a6eb6 50%, #e9def0 100%);
    
    background-position: -100% 100%;
	background-repeat: repeat-x;
	background-size: 200% 2px;
	animation: obfuscatedWord 3s ease-in-out alternate infinite;
    border-radius: 3px;

}
@keyframes obfuscatedWord {
	0% {
		background-position: -100% 100%;
	}
	100% {
		background-position: 100% 100%;
	}
}

@keyframes wordDisplay {
	0% {
		transform: translateY(50px);
		opacity: 0;
	}
	100% {
		transform: translateY(0px);
		opacity: 1;
	}
}

@media only screen and (max-width: 800px) {
	#Extract {
		font-size: 1em;
		height: calc(100vh - 30vh - 140px)
	}
	.extractPanel {
		width: 100vw;
		margin: auto;
		padding: 10px;
		box-sizing: border-box;
	}
}