.boxJoinGame {
    width: 35vw;
    margin: auto;
    padding: 20px;
    margin-bottom: 8vh;
}

h2 {
    margin: 0;
    margin-bottom: 10px;
}


.inputUsername {
    width: 100%;
    box-sizing: border-box;
    font-size: 1.3em;
    padding: 5px;
    border: 0;
    border-bottom: 3px solid #CCC;
    font-family: 'open-sans',sans-serif;
    transition: all .2s ease-in-out;
}

.inputUsername:hover { 
    border-bottom: 3px solid rgb(216, 193, 231);
 }

.inputUsername:focus { 
    outline: none;
    border-bottom: 3px solid rgb(179, 132, 211);
    background-color: #fdfdfd;
 }

 .nameLanguageInputs {
    display:flex;
    margin: 0px 0px 7px 0px;
}
.inputLanguage {
	/* background-color: #ede7ee; */
    background-color: white;
	border: none;
	font-size: 1em;
	padding: 0 5px;
	text-align: center;
	cursor: pointer;
    transition: all .2s ease-in-out;
    border-bottom: 3px solid #ccc;
border-left: 2px solid rgb(241, 238, 238);
}
.inputLanguage:hover{
	/* background-color: #e4dbe6; */
    border-bottom: 3px solid rgb(178, 199, 238);
}
.inputLanguage:focus {
    /* background-color: #f7f5f8 */
    border-bottom: 3px solid rgb(126, 167, 241);
}
.buttonJoinGame, .buttonCreateGame {
    width: 100%;
    font-size: 1.6em;
    border: none;
    margin: 5px 0px;
    border-radius: 3px;
    color: #faf9f4;
    cursor: pointer;
    transition: all .1s ease-in-out;
    
    font-family: 'neue-kabel',sans-serif;
}
.buttonJoinGame {
    background-color: rgb(75, 179, 97);
    border: 1px solid rgb(61, 150, 81);
    text-shadow: 2px 2px 0px rgb(61, 150, 81);
    padding: 13px;
    font-weight: 600;
}
.buttonJoinGame:hover:not(.buttonJoinGame:disabled) {
    background-color: rgb(100, 199, 122);
}
.buttonJoinGame:active {
    background-color: rgb(61, 150, 81);
}
.buttonJoinGame:disabled, .buttonJoinGame:disabled:hover {
    /* color: #faf9f47a;
    background-color: rgb(119, 128, 121);
    border: 1px solid rgb(77, 88, 80);
    text-shadow: none; */
    filter:saturate(.2)
}

.buttonCreateGame {
    background-color: rgb(126, 167, 241);
    border: 1px solid rgb(108, 138, 194);
    text-shadow: 1px 1px 0px rgb(108, 138, 194);
    padding: 4px;
    font-size: 1.2em;
}
.buttonCreateGame:hover{
    background-color: rgb(146, 180, 245);
}
.buttonCreateGame:active{
    border: 1px solid rgb(108, 138, 194);
}

.credits {
    color: #faf9f4;
    font-style: italic;
    position: absolute;
    text-decoration: none;
    right:1vh;
    bottom:1vh;
}
.credits .name {
    color: #faf9f4;
    border-bottom:1px solid #faf9f4;
}

.hiddenWord {
    /* background-color: #CCC; */
    background-image: linear-gradient(to right, #8548ad 0%, #713699 50%, #8548ad 100%);
    
    background-position: 0 1.04em;
    background-repeat: repeat-x;
    /* background-size: 10px 2px; */

    /*border-bottom:2px solid #2a123a;*/
    color: #faf9f400;
    border-radius: 3px;
}
.formError {
    color:rgb(150, 61, 61);
}




@media only screen and (max-width: 800px) {
    .boxJoinGame {
        width: calc(100vw - 10px);
        box-sizing: border-box;
        margin-right: 5px;
        margin-left: 5px;
        margin-bottom: 10px;
    }
    #App {
        overflow-y: auto!IMPORTANT;
    }
}