#PlayerList {
    width: 20vw;
    position: absolute;
    right:2vw;
    background-color: transparent;
    animation: fadeIn .2s ease-out;
    top: 2vw;
}

.list_User, .list_UserFound {
    font-size: 1.4em;
    padding: 10px 10px;
    list-style: none;
    border-bottom: 2px solid #ccc;
    overflow: hidden;
    background-color: #fafafa;
    text-overflow: ellipsis;
    display: flex;
    justify-content: left;
}
.list_Username {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}


#PlayerList li:first-child {
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
}
#PlayerList li:last-child {
    border-bottom: none;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
}

.list_UserFound {
    background-color: rgb(126, 167, 241);
    /* background: linear-gradient(-45deg,#996ab6,  #7c40a1,#7c40a1, #996ab6);rgb(126, 167, 241) */
    background: linear-gradient(-45deg,rgb(126, 167, 241), rgb(148, 181, 243),rgb(126, 167, 241), rgb(83, 122, 196));/*rgb(126, 167, 241)*/
    /* background-size: 200% 200%; */
    text-shadow: 1px 1px 0px #6d6014;
    color: #fafafa;
    /* transform: translateX(-20px); */
    /* animation: gradientBG 1s cubic-bezier(.46,.03,.52,.96) infinite alternate; */
}
@keyframes gradientBG {
	0% {
		background-position: 0% 50%;
	}
	100% {
		background-position: 100% 50%;
	}
}

.list_Score {    
    color: #2a123a69;
    margin-left: 10px;
    text-shadow: none;
}

.list_UserFound .list_Score {
    color: #f5ecfca1;
}
.list_You {
    font-weight: bold;
}
.list_TooltipHidden {
    display: none;
}
.list_Tooltip {
    font-size: 0.8em;
    padding:5px 10px;
    border-radius: 3px;
    background-color: #fafafa;
    text-align: right;
    transform: translateX(calc(-100% - 20px));
    position: absolute;
    animation: tooltipSide 1s ease-out forwards 1;
    box-shadow: 1px 1px 3px #4c146d;
}
.list_Tooltip:after {
    content: " ";
    background-color: #fafafa;
    position: absolute;
    width:10px;
    height: 10px;
    display: inline-block;
    transform: translate(5px, 7px) rotate(45deg) ;
}

.list_UserFound .list_Tooltip {
    background-color:rgb(126, 167, 241);
    color: #fafafa;
    font-weight: 600;
}

.list_UserFound .list_Tooltip:after {
    background-color:rgb(126, 167, 241);
}


@keyframes tooltipSide {
    0% {
        transform: translateX(calc(-100% - 0px));
        opacity: 0;
    }
    30%, 80% {
        opacity: 1;
    }
    100% {
        transform: translateX(calc(-100% - 50px));
        opacity: 0;
    }
}

@keyframes tooltipBottom {
    0% {
        transform: translateY(calc(100% - 10px));
        opacity: 0;
    }
    30%, 80% {
        opacity: 1;
    }
    100% {
        transform: translateY(calc(100% + 20px));
        opacity: 0;
    }
}

@media only screen and (max-width: 800px) {
    #PlayerList{
        position: inherit;
        width: 100%;
        display: flex;
        box-shadow: none;
        flex-wrap: wrap;
        justify-content: center;
    }
    #PlayerList li:last-child, #PlayerList li:first-child {
        border-radius: 0px;
    }
    .list_User, .list_UserFound {
        border: 0px;
        font-size: 1em;
        width: 20vw;
        box-sizing: border-box;
        text-align: center;
        justify-content: center;
    }
    .list_Score{
        margin-left: 5px;
    }
    .list_Tooltip {
        display: none;
        animation: tooltipsBottom 1s ease-out forwards infinite;
        text-align: center;
        transform: translateY(calc(100% + 0px));
    }
    .list_Tooltip:after {
        display: none;
        top:0px;
        left:40%;
        transform: translate(0px, -4px) rotate(45deg) ;
    }
}