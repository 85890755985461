.roomSettings , .linkShareBox{
    width: 30vw;
    margin-left: 25vw;
    padding: 20px;
  margin-top: 4vw;
  animation: fadeIn .2s ease-out;
}

.formRoomSettings {
  user-select: none;
}
.formRoomSettings label {
    font-size: 1.3em;
    font-weight: bold;
}
.formRoomSettings label .settingValue {
    margin-left: 6px;
    font-weight: normal;
}

.rangeInput {
    margin-bottom: 20px;
}

.settingsMessage {
    text-align: center;
    width: 100%;
    display: inline-block;
    font-style: italic;
}

.settingsDisabled {
    opacity: .5;
    filter: saturate(.5);
}

.linkShareBox{
  margin-top: 2vw;
  padding-top: 15px;
}
.linkSharePrompt {
  display: inline-block;
  margin-bottom: 1vh;
  font-variant: small-caps;
}


input[type=range] {
  -webkit-appearance: none; /* Hides the slider so that custom slider can be made */
  width: 100%; /* Specific width is required for Firefox. */
  background: transparent; /* Otherwise white in Chrome */
}

input[type=range]::-webkit-slider-thumb {
  -webkit-appearance: none;
}

input[type=range]:focus {
  outline: none; /* Removes the blue border. You should probably do some kind of focus styling for accessibility reasons though. */
}

input[type=range]::-ms-track {
  width: 100%;
  cursor: pointer;

  /* Hides the slider so custom styles can be added */
  background: transparent; 
  border-color: transparent;
  color: transparent;
}
/* Special styling for WebKit/Blink */
input[type=range]::-webkit-slider-thumb {
  -webkit-appearance: none;
  border: 1px solid rgb(108, 138, 194);
  height: 12px;
  width: 12px;
  border-radius: 100px;
  background: rgb(126, 167, 241);
  cursor: pointer;
  margin-top: -4px; /* You need to specify a margin in Chrome, but in Firefox and IE it is automatic */
}

/* All the same stuff for Firefox */
input[type=range]::-moz-range-thumb {
  border: 1px solid rgb(108, 138, 194);
  height: 12px;
  width: 12px;
  border-radius: 100px;
  background: rgb(126, 167, 241);
  cursor: pointer;
}

/* All the same stuff for IE */
input[type=range]::-ms-thumb {
  border: 1px solid rgb(108, 138, 194);
  height: 12px;
  width: 12px;
  border-radius: 100px;
  background: rgb(126, 167, 241);
  cursor: pointer;
}



input[type=range]::-webkit-slider-runnable-track {
  width: 100%;
  height: 4px;
  cursor: pointer;
  background: #ccc;
  border-radius: 100px;
}

input[type=range]:focus::-webkit-slider-runnable-track {
  background: #ccc;
}

input[type=range]::-moz-range-track {
  width: 100%;
  height: 4px;
  cursor: pointer;
  background: #ccc;
  border-radius: 100px;
}

input[type=range]::-ms-track {
  width: 100%;
  height: 4x;
  cursor: pointer;
  background: transparent;
  border-color: transparent;
  color: transparent;
}
input[type=range]::-ms-fill-lower {
  width: 100%;
  height: 4px;
  cursor: pointer;
  background: #ccc;
  border-radius: 100px;
}
input[type=range]:focus::-ms-fill-lower {
  background: #ccc;
}
input[type=range]::-ms-fill-upper {
  background: #ccc;
  border-radius: 100px;
}
input[type=range]:focus::-ms-fill-upper {
  background: #ccc;
}

@media only screen and (max-width: 800px) {
  .roomSettings, .linkShareBox {
    width: 10px;
    width: calc(100vw - 10px);
    margin-left: 5px;
    margin-right: 5px;
    box-sizing: border-box;
  }
}