#AdminPanel {
	display: flex;
    flex-direction: column;
	width: 100vw;
	height: 100vh;
}


.boxAdmin {
	width: 35vw;
	margin: auto;
	padding: 20px;
}

.statsPanel {
    width:100vw;
    height: 20vh;
    display: flex;
    justify-content: space-around;   
}

.languageStat {
	font-size: 3em;
	font-weight: bold;
	text-align: center;
    padding: 25px;
    margin: 20px;
}


.bottomlanguageStat {
	font-size: 0.5em;
}

.adminButtons {
	flex-direction: column;
	display: flex;
	align-items: center;
}

.adminButton {
	padding: 10px;
	font-size: 1.3em;
	font-weight: bold;
	margin: 15px;
	min-width: 20vw;
	text-align: center;
    cursor: pointer;
    line-height: 1em;
	border: none;
}

.adminButton span {
    font-weight: normal;
    font-size: 0.8em;
    font-style: italic;
}

.inputSearch {
	font-size: 1.8em;
padding: 7px;
box-shadow: 1px 1px 7px rgba(14,19,48,.3843137254901961);
border: none;
width: 50vw;
text-align: center;
box-sizing: border-box;
}

.buttonSearch {
	width : auto;
	font-size: 1.5em;
	font-weight: bold;
	margin: 0 20px;
height: 60px;
}

.getWikiDataForm {
	text-align: center;
	padding: 20px;
}
.getWikiDataForm .formError {
	background-color:#FFF;
	padding:5px 10px;
	border-radius: 0 0 3px 3px;
}

.getWikiDataForm .formError:empty {
	padding:0;
}

.languageSelect {
	font-size: 1.4em;
	padding: 5px;
	margin: 0 20px;
}

.adminWordLimit {
	display: inline-block;
	text-align: center;
	width: 100vw;
}

.adminExtract, .changeExtract, #addToDatabaseForm h2, .didyoumeanContainer {
	width: 75vw;
	margin:auto;
}

.changeExtract {
	text-align: center;
}

.didyoumeanContainer {
	color: #FFF;
}

.didyoumean {
	padding: 0px 10px;
	margin-right:5px;
	background-color: #fff;
	color: #7c40a1;
	line-height: 2.5em;
	border-radius:2px;
	margin: 5px 7px 5px 0px;
	cursor:pointer;
	transition: all .2s ease-in-out
}

.didyoumean:hover {
	background-color: rgb(235, 235, 235);

}

.backButton {
	background: top;
	border: 0;
	font-size: 3em;
	font-family: 'neue-kabel', sans-serif;
	color: #fff;
	position: fixed;
	font-weight: bold;
	top: 5px;
	left: 18px;
	padding: 0;
	cursor: pointer;
}