/* .WordFoundAnimationContainer {
    width: 100vw;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;

    display: flex;
    justify-content: center;
    align-items: center;

    pointer-events: none;
    touch-action:none;
} */

.wordFoundAnimCanvas {
    display:flex;
    justify-content:center;
    align-items:center;
    width:100vw;
    height:100vh;
    position: absolute;
    top: 0;
    left: 0;

    z-index: 50;
  
    pointer-events: none;
    touch-action:none;
}

.WordFoundAnimationWrapper {
  background: transparent;
  display:inline-block;
    animation: wordFoundAnimationWrapper 1.7s ease forwards;
}

.WordFoundAnimation {
  width:100%;
  box-sizing: border-box;
  transition: width .5s ease;
  overflow:hidden;
  
  background-color: rgb(75, 179, 97);
    background: linear-gradient(-45deg,rgb(75, 179, 97),rgb(104, 218, 129),rgb(75, 179, 97),rgb(58, 148, 78));
  color: #fafafa;
    font-size: 2.7em;
    font-weight: 700;
  text-shadow: 3px 3px 0px rgb(58, 148, 78);
    box-shadow: 5px 5px 0px rgb(44, 122, 61);
  transform: skewX(-5deg);
  
  animation: wordFoundAnimation 1.7s ease forwards;
}

.WordFoundAnimation span {
  padding: .2em .4em;
  display: inline-block;
  white-space: nowrap;
    z-index: 50;
}


@keyframes wordFoundAnimation {
  0% {
    width:0%;
  }
  20%{
    width:100%;
  }
  80% {
    width: 100%;
  }
  100% {
    width: 0%;
  }
}

@keyframes wordFoundAnimationWrapper {
  0% {
    transform: translateX(50%);
  }
  20% {
    transform: translateX(0%);
  }
  30% {
    transform: scale(1.4)
  }
  80% {
    transform: scale(1.4)
  }
  100% {
    transform: translateX(50%) scale(1.4);
  }
}