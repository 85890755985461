.localeSelector {
    position: absolute;
    top: 15px;
    left: 15px;

}

.localeSelector {
    appearance: none;
    background-color: #fcf9fd;
    border: none;
    padding: 5px 5px;
    margin: 0;
    font-family: inherit;
    
    line-height: inherit;
    
    font-weight: bold;
    font-size: 1em;

    cursor: pointer;
    text-align: center;

    transition: all .2s ease-in-out;
}

.localeSelector:hover {
    background-color: #f1ebf3;
}