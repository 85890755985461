#Chat {
    position:absolute;
    right: 2vw;
    top: 2vh;
    width:20vw;
    height: calc(100% - 4vh);
    font-size: 1.2em;
}
.chatMessages {
    overflow: auto;
    height: 100%;
}

#Chat li {
    list-style: none;
}

#gradient {
    pointer-events: none;
    touch-action:none;
    background: linear-gradient(to bottom,  rgba(255,255,255,0) 60%,rgba(253,253,253,1) 99%);
    
    position:absolute;
    z-index:2;
    right:0; bottom:0; left:0;
    height:100%;
}

.chat_Message {
    color: #2a123a;
}
.chat_User {
    color: #2a123a69;
    font-weight: bold;
}
.chat_UserJoined {
    color: rgb(61, 150, 81);
    font-weight: bold;
}
.chat_UserLeft {
    color: rgb(150, 61, 61);
    font-weight: bold;
}
.chat_Answer {
    color: #2a123a;
    font-weight: bold;
}
.chat_RoundStarted {
    color: rgb(108, 138, 194);
    font-weight: bold;
}
.chat_UserFound {
    color: #9255b8;
    font-weight: bold;
}
.chat_UserClose {
    color: rgb(149, 172, 214);
    font-weight: bold;
    font-style: italic;
}


@media only screen and (max-width: 800px) {
  #Chat {
        margin:auto;
        width: calc(100vw - 40px);
        height: calc(30vh - 50px);
        margin-bottom: 50px;
        position: initial;
        padding: 0px 5px;
        order:1;
        overflow: auto;
        padding-top: 5px;
        font-size: 1em;
  }
  #gradient {
      display: none;
  }
}