#Title {
	text-align: center;
    user-select: none;
}


h1 {
    color: #faf9f4;
    margin: auto;
    text-align: center;
    font-size: 4em;
    margin-top: 8vh;
    margin-bottom: 8vh;
    text-shadow: 4px 4px 0px #713699;
    display: inline-block;
    /* animation: letterSpacing 1s backwards cubic-bezier(.68,-0.55,.27,1.55) .4s */
    

    background: linear-gradient(to right, #fafafa 0%, #fafafa 100%);
    background-size: 100% 5px;
    background-position: 100% 100%;
    background-repeat: no-repeat;

    animation: lineDisappear .4s cubic-bezier(0,0,.79,1) forwards .4s
}


.titleAnimation span{
    animation: lettersUp .4s backwards ease-out;
    animation-delay: .3s;
    display: inline-block;
}
.titleAnimation span:nth-child(2) {
    animation-delay: .4s;
}
.titleAnimation span:nth-child(3) {
    animation-delay: .5s;
}
.titleAnimation span:nth-child(4) {
    animation-delay: .6s;
}
.titleAnimation span:nth-child(5) {
    animation-delay: .7s;
}
.titleAnimation span:nth-child(6) {
    animation-delay: .8s;
}
.titleAnimation span:nth-child(7) {
    animation-delay: .9s;
}


@keyframes lettersUp {
    0% {
        transform: translateY(40px) scale(1);
        opacity: 0;
    }
    60% {
        transform: translateY(0px) scale(1.2);
        opacity: 1;
    }
    100% {
        transform: translateY(0px) scale(1);
    }
}
@keyframes letterSpacing {
    0% {
        letter-spacing: 0px;
        /* transform: rotate(0deg); */
    }
    50% {
        letter-spacing: 10px;
        /* transform: rotate(-4deg); */
    }
    100% {
        letter-spacing: 0px;
        /* transform: rotate(0deg); */
    }
}
@keyframes lineDisappear{
    0% {
        background-size: 100% 5px;
    }
    100% {
        background-size: 0% 5px;
    }
}


@media only screen and (max-width: 800px) {
    h1 {
        margin-top: 4vh;
        margin-bottom: 4vh;
    }
}