#bottomUI {
    background-color: #fdfdfd;
    width: 100vw;
    height: 40vh;
    position: absolute;
    bottom:0;
    box-shadow: 0px -2px 25px rgba(0, 0, 0, 0.055), 0px -2px 5px rgba(0, 0, 0, 0.288);
    animation: bottomUI .4s;
}

@keyframes bottomUI {
    0% {
        opacity: 0;
        transform: translateY(200px);
    }
    100% {
        opacity: 1;
        transform: translateY(0px);
    }
}

#connectToRoom .box{
    width: 50%;
    margin: auto;
    padding: 20px;
    margin-top: 4vh;
}

.joinMessage {
    text-align: center; 
    font-size: 1.5em;
    margin-top: 0;
}

.playerCount {
    font-size: 1.3em;
    text-align: center;
    margin-top: 0;
}
.playerMax {
    opacity: .5;
}

.audioOn, .audioOff {
    position: absolute;
    background-repeat: no-repeat;
    background-size: 35px;
    height: 35px;
    width: 35px;
    top: 15px;
    left: 15px;
    cursor: pointer;
    transition: all .1s ease-in-out;
    opacity: .7;
}
.audioOn:hover, .audioOff:hover{
    opacity: 1;
    transform: rotate(13deg);
}

.audioOn {
    background-image: url(../img/soundOn.svg);
}
.audioOff {
    background-image: url(../img/soundOff.svg);
}


@media only screen and (max-width: 800px) {
    #bottomUI {
        height: auto;
        display: flex;
        flex-direction: column;
        max-height: 30vh;
    }
    
    .audioOn, .audioOff {
        top:unset;
        bottom: calc(30vh + 7px);
        left: 15px;
        /* background-color: blueviolet; */
    }
}